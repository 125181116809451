import React, { useState, useEffect } from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import SmallBanner from "@components/common/SmallBanner";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import Products from "@components/sections/Products";
import dayjs from "dayjs";
import axios from "axios";
import useSWR from "swr";
import ProductsNew from "@components/sections/products2/Products";
import { Link } from "gatsby";

const endpoints = {
    products: "/.netlify/functions/products",
};

const Tienda = () => {
    const {
        data: result,
        isValidating,
        isLoading,
    } = useSWR(endpoints.products, axios.get, {
        dedupingInterval: 30 * 1000,
    });
    const [productsState, setProductsState] = useState([]);

    useEffect(() => {
        if (result?.data?.products) {
            let products = result?.data?.products.filter(
                x => x.enabled && !x.hidden && x?.category?.includes("atlantic"),
            );

            products = products.map(p => {
                const { created_at, updated_at, ...rest } = p;
                return {
                    ...rest,
                    created_at: dayjs(created_at).toDate(),
                    updated_at: dayjs(updated_at).toDate(),
                };
            });

            products = products.sort((a, b) => b.stock - a.stock || b.created_at - a.created_at);
            setProductsState(products);
        }
    }, [result]);

    return (
        <>
            <Layout>
                <Seo
                    title="Nuestra tienda: Formación Policial Atlàntic"
                    description="Formación continuada en Seguridad Ciudadana Avanzada para Policías Locales."
                />
                <SmallBanner
                    title="Formación Policial Atlàntic"
                    alt="Formación Policial Atlàntic"
                    subtitle="Formación continuada en Seguridad Ciudadana Avanzada para Policías Locales."
                    filename="bgs/bgAtlantic"
                />

                <div className="container lg:px-0 py-20">
                    <Title>¿En qué nos diferenciamos?</Title>
                    <Text className="text-justify space-y-3">
                        <p>
                            Perfecciona tus habilidades en situaciones de emergencia y defensa con
                            nuestros programas avanzados. Entrenamos tu resistencia, agilidad y
                            técnicas para responder eficazmente a cualquier desafío.
                        </p>
                        <p>
                            Mejora tu interacción con la comunidad mediante técnicas avanzadas de
                            negociación y mediación. Nuestro enfoque integral te prepara para
                            manejar conflictos de manera pacífica y efectiva.
                        </p>
                        <p>
                            Descubre más información sobre nuestras formaciones en{" "}
                            <Link
                                href="https://www.formaciopolicialatlantic.cat/"
                                rel="noopener noreferrer"
                                target="_blank"
                                className="font-semibold text-cpurple"
                            >
                                Formación Policial Atlàntic
                            </Link>
                            .
                        </p>
                    </Text>

                    <div className="mt-10 space-y-10">
                        {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                            <ProductsNew
                                items={productsState}
                                loading={isLoading || isValidating}
                            />
                        ) : (
                            <Products items={productsState} />
                        )}

                        <div className="mt-10 text-right text-xs italic font-montserrat text-gray-500">
                            * I.V.A. incluido en todos los precios
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default Tienda;
